.content-layout {
  transition: margin-left ease-in-out .5s;
  background-color: var(--surface-f);
  min-height: 100%;
  .container {
    background-color: var(--surface-f);
    .empty-city-msg {
      color: var(--primary-color);
    }
  }
  .main-drawer-fixed  {
    z-index: 1001;
    box-shadow: -5px -5px 5px #efefef inset;
  }
  &.drawer-opened {
    margin-left: 24rem;
  }
  &.dark {
    .main-drawer-fixed {
      box-shadow: -5px -5px 5px #000000 inset;;
    }
  }
}