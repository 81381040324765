.app-loading {
  &-indicator {
  position: relative;
  width: 100%;
  height: 100%;
  .p-progress-spinner {
    position: relative;
    opacity: 0;
    z-index: 2;
    transition: all .5s ease;
  }
  &.show-loading {
    .p-progress-spinner {
      opacity: 1;
    }
    .app-loading-logo {
      transform: scale(50%);
    }
  }
}
&-logo  {
  background-image: url('../assets/images/ids_logo_transparent.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all .5s ease;
}
  
  // &.shrink-logo {
  //   animation-name: shrinkLogo;
  //   animation-duration: 1s;
  //   animation-delay: .5s;
  //   animation-direction: forwards;
  //   animation-fill-mode: forwards;
  // }
  // &.growLogo {
  //   background-size: 50%;
  //   animation-name: growLogo;
  //   animation-duration: 1s;
  //   animation-delay: .5s;
  //   animation-direction: forwards;
  //   animation-fill-mode: forwards;
  // }
}
// @keyframes shrinkLogo {
//   0% {background-size: 100%;}
//   50% {background-size: 50%;}
//   100% {background-size: 50%;}
// }
// @keyframes growLogo {
//   0% {background-size: 50%;}
//   50% {background-size: 100%;}
//   100% {background-size: 100%;}
// }