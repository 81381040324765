.appbar {
  display: flex;
  padding: 0.5rem;
  background-color: var(--surface-e);
  box-shadow: -2px -4px 15px 0px #ccc;
  position: relative;
  z-index: 1000;
  min-height: 77px;
  align-items: center;
}
.dark {
  .appbar {
    box-shadow: -2px -4px 15px 0px rgb(0, 0, 0);
  }
}