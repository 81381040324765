.main-drawer {
  background: var(--surface-e);
  width: 24rem !important;
  .logo-container {
    // margin-right: calc(46px + 1rem);
    position: absolute;
    left: .7rem;
    top: .3rem;
  }
  .p {
    &-sidebar {
      &-content {
        background-image: url('../assets/images/logos_coloridas_2.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center bottom;
      }
    }
    &-menu {
      width: auto;
      border: none;
      margin-top: 1rem;
    }
  }
  &.main-drawer-fixed {
    position: fixed;
    min-width: 24rem;
    top: 0;
    left: 0;
    bottom: 0;
    transition: all ease-in-out .5s;
    padding-top: 70px;
    transform: translate(-24rem, 0);
    .drawer-close {
      transition: all ease .3s;
    }
    .main-drawer-content {
      transition: all ease .3s;
    }
    &.drawer-fixed-active {
      transform: translate(0, 0);
    }
    &.drawer-fixed-inactive {
      .drawer-close {
        visibility: hidden;
      }
      // .main-drawer-content {
      //   display: none;
      // }
      // .main-drawer-fixed-inner {
      //   background-image: none;
      // }
    }
    .drawer-close {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 1000;
    }
    .main-drawer-fixed-inner {
      height: 100%;
      padding: 0.571rem 1rem;
      background-image: url('../assets/images/logos_coloridas_2.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center bottom;
      overflow-y: auto;
    }
  }
  .theme-config {
    margin-top: 30px;
    .font-size-select {
      zoom: 0.9;
    }
  }
}