.app {
  &-logo {
    zoom: 0.7;
    &-sigla {
      padding-left: 10px;
    }
  }
}
.dark {
  .app {
    &-logo {
      &-text {
        img {
          filter: invert(1)
        }
      }
    }
  }
}