.app-icon-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 10px;
  transition: all ease .3s;
  // transform: translate(-3rem, 0);
  transform: translate(23.6rem, 0);
  opacity: 0;
  .p-button {
    margin: 5px 0;
  }
  .p-button.p-button-icon-only.p-button-rounded {
    width: 2.75rem;
    height: 2.75rem;
  }
  .p-button.p-button-lg .p-button-icon {
    font-size: 1.75rem;
  }
}
.drawer-fixed-inactive .app-icon-menu {
  opacity: 1;
  // transform: translate(23.6rem, 0);
}