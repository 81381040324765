html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}
#root {
  height: 100%;
}

.ids-logo-mini {
  display: inline-block;
  height: 40px;
  width: 40px;
  margin-left: 5px;
  margin-right: 5px;
  background: url('assets/images/ids_logo_transparent.png') no-repeat center left;
  background-size: 40px;
  transition: width .3s ease;
}
.drawer-opened .ids-logo-mini {
  width: 0;
}

path.leaflet-interactive:focus {
  outline: none;
}

/* .ids-tooltip-drawer .p-tooltip-text {
  background-color: #fff;
  padding: 13px;
}
.ids-tooltip-drawer.p-tooltip-right {
  margin-left: 20px;
}
.ids-tooltip-drawer.p-tooltip-left {
  margin-right: 20px;
}
.ids-tooltip-drawer.p-tooltip-left .p-tooltip-arrow {
  border-left-color: #fff;
}
.ids-tooltip-drawer.p-tooltip-right .p-tooltip-arrow  {
  border-right-color: #fff;
}
.ids-tooltip-drawer.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: #fff;
} */