@import 'primeflex/primeflex.scss';

:root {
  --map-tiles-filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3) brightness(0.7);
}

@media (prefers-color-scheme: dark) {
  .dark-tiles {
    filter:var(--map-tiles-filter, none);
  }
}

html {
  font-size: 12px;
}

body {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

.container {
  padding: 1rem 1.75rem;
  @media screen and (min-width: 966px) {
    padding: 2rem 3.5rem;
  }
}

.leaflet-action-button {
  background-color: #fff;
  border: none;
  cursor: pointer;
  outline: none;
  width: 30px;
  height: 30px;
  &:hover {
    background-color: #f4f4f4;
  }
}
.leaflet-control-attribution {
  display: none;
}
@keyframes ui-progress-spinner-color {
  100%,
  0% {
      stroke: #9e3419;
  }
  40% {
      stroke: #ab4a1d;
  }
  66% {
      stroke: #d39d22;
  }
  80%,
  90% {
      stroke: #606060;
  }
}

.full-map-page {
  .leaflet-top.leaflet-right {
    .leaflet-bar {
      border-radius: 0;
      border: none;
    }
  }
  .leaflet-touch .leaflet-bar {
    border: none;
  }
  .left-map-step {
    position: absolute;
    width: 10px;
    height: 100px;
    z-index: 1000;
    left: 0;
    top: calc(50% - 50px);
  }
  .center-map-step {
    position: absolute;
    width: 1px;
    height: 1px;
    z-index: 1000;
    left: calc(50% - .5px);
    top: calc(50% - .5px);
  }
  &.is-mobile {
    .floating-container-top-left {
      top: 5px;
      left: 5px;
      right: 5px;
      z-index: 1001;
    }
    .floating-container-top-right {
      top: 60px;
      left: 0;
      padding: 5px;
    }
  }
}
.p-card.bordered {
  border: 1px solid var(--primary-color);
}

.theme-dark {
  .p-dialog {
    border: 1px solid var(--primary-color);
  }
}